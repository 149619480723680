import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj23 = () => {
  return (
    <Layout>
      <SEO title="Lottery Simulation" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-23">
          <div className="py-1-heading">
            <h1>Lottery Simulation</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Lottery%20Simulation"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              Many people believe in luck
              <br />
              <br />
              So, lets see, if luck can buy you some money
              <br />
              <br />
              Here, each day a new lottery released,
              <br />
              having possible no. on lottery is 1 to 10
              <br />
              but only one no. contain the price
              <br />
              Buying price for the lottery is 100 Rs. <br />
              Amount of lottery is 10 times, that is, 1000 Rs.
              <br />
              <br />
              We will observe for a week,
              <br /> and calculate our total profit and loss
              <br />
              You can also check for a year,
              <br />
              <br />
              Oh! I played this, and observe for a year,
              <br />
              and I am under debt of 12200 Rs.
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Matplotlib</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Pyplot</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Random</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj23
